import {
  Box,
  Button,
  SimpleGrid,
  Heading,
  Image,
  Text,
  Divider,
} from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent, useState } from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { Title } from "../components/title"
import Lightbox from "fslightbox-react"

const GalleryTemplate: FunctionComponent<{
  data: any
  pageContext: { lang: string }
}> = ({ data }) => {
  const { items, primary } = data.prismicRacesBodyImageGallery
  const [isVisible, setIsVisible] = useState(false)
  const [slide, setSlide] = useState(0)

  const showSlide = slide => {
    setIsVisible(!isVisible)
    setSlide(slide)
  }
  const urls = items.map(image => image.gallery_image.url)

  return (
    <Layout>
      <SEO title={primary.name_of_the_gallery?.text} />
      <Title text={primary.name_of_the_gallery?.text} />

      <Lightbox toggler={isVisible} slide={slide} sources={urls} />
      <SimpleGrid columns={[1, 2, 3, 4]} spacing={10}>
        {items.map((image, index) => (
          <Box
            key={index}
            onClick={() => showSlide(index + 1)}
            cursor="pointer"
          >
            <Image
              src={image.gallery_image.fluid?.src}
              alt={image.image_captions?.text}
              w="100%"
            />
            <Text>{image.image_captions?.text}</Text>
          </Box>
        ))}
      </SimpleGrid>
        {primary.more?.url && (
          <Button
            as="a"
            href={primary.more?.url}
            alignSelf="center"
            variantColor="green"
            size="lg"
            target="_blank"
            mb={6}
            mt={8}
          >
            További képek 
          </Button>
        )}
    </Layout>
  )
}

export const query = graphql`
  query GalleryByID($uid: String!) {
    prismicRacesBodyImageGallery(id: { eq: $uid }) {
      primary {
        name_of_the_gallery {
          text
        }
        more {
          url
        }
      }
      items {
        gallery_image {
          url
          fluid(maxHeight: 370, maxWidth: 370) {
            src
          }
        }
        image_captions {
          text
        }
      }
    }
  }
`

export default GalleryTemplate
